@import "~rc-checkbox/assets/index.css";

$checkbx-width: 24px;
$checkbox-border-color: #ffffff;
$checkbox-background-color: #ffffff;

.rc-checkbox-inner {
  width: $checkbx-width;
  height: $checkbx-width;
  padding: 1px;
  border-radius: 50%;
  box-sizing: border-box;
}

.rc-checkbox {
  &:hover,
  &-checked:hover {
    .rc-checkbox-inner {
      border-color: var(--primary-color);
    }
  }

  &-checked {
    .rc-checkbox-inner {
      border-color: $checkbox-border-color;
      background-color: $checkbox-background-color;

      &:after {
        position: static;
        transform: none;
        border: none;
        margin: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: var(--primary-color);
      }
    }
  }
}
