@import "src/sass/styles";

body {
  // overflow: hidden !important;
  overflow-x: clip;
  background-color: var(--bg-color);
  color: var(--primary-text-color);
  font-family: var(--font-family);
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

.AppContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

// Custom scrollbar

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar:horizontal {
  width: unset;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: var(--cell-background-color);
  border-radius: 8px;
  margin: 2px;
}

::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 8px;
  &:hover {
    background: var(--primary-color-d-50);
  }
}
