:root {
  // design system (available in studio)
  --bg-color: rgba(0, 0, 0, 1);
  --primary-color: rgba(37, 135, 255, 1);
  --secondary-color: rgba(255, 255, 255, 1);
  --primary-text-color: rgba(255, 255, 255, 1);
  --secondary-text-color: rgba(242, 242, 242, 1);
  --header-bg-color: rgba(0, 0, 0, 1);
  --header-link-color: rgba(255, 255, 255, 1);
  --header-link-hover-color: rgba(133, 133, 133, 1);
  --footer-bg-color: rgba(0, 0, 0, 1);
  --footer-link-color: rgba(255, 255, 255, 1);
  --footer-link-hover-color: rgba(133, 133, 133, 1);
  --focus-bg-color: rgba(255, 255, 255, 1);
  --focus-text-color: rgba(0, 0, 0, 1);
  --tertiary-bg-color: rgba(39, 39, 39, 1);
  --tertiary-text-color: rgba(208, 208, 208, 1);

  // design system (not available in studio)
  --bg-secondary-color: rgba(20, 20, 20, 1); // transformed from bg
  --bg-tertiary-color: rgba(39, 39, 39, 1); // transformed from bg
  --focus-color: rgba(0, 92, 212, 1); // transformed from primary
  --icon-light-color: rgba(208, 208, 208, 1); // hardcoded
  --icon-dark-color: rgba(133, 133, 133, 1); // hardcoded
  --tertiary-text-color: rgba(208, 208, 208, 1); // hardcoded
  --error-color: rgba(244, 82, 82, 1); // hardcoded
  --progress-ads-color: rgba(253, 185, 19, 1); // hardcoded

  // custom hardcoded
  --bg-gradient-color: rgba(0, 0, 0, 0.8);

  // custom (available in studio - should be removed?)
  --cell-background-color: rgba(20, 20, 20, 1);

  // custom (not available in studio - should be removed?)
  --primary-color-d-50: scale(rgba(37, 135, 255, 1), $lightness: -50%);
  --primary-text-color-d-30: scale(rgba(255, 255, 255, 1), $lightness: -30%);
  --bg-color-o-30: opacify(#000000, 30%);
  --link-color: rgba(37, 135, 255, 1);
  --link-color-d-50: scale(rgba(37, 135, 255, 1), $lightness: -50%);
  --link-text-color: rgba(255, 255, 255, 1);
  --link-text-color-d-30: scale(rgba(255, 255, 255, 1), $lightness: -30%);

  // others
  --font-family: "Montserrat", Helvetica, Arial, sans-serif;
  --app-padding-top: 70px;
  --app-padding-left: 55px;
  --app-padding-right: 63px;
  --app-padding-bottom: 64px;
}
