.MaintenanceScreen {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__icon {
    width: 20vw;
    height: 20vw;
    background-image: url("../../resources/maintenance-image.png");
    background-size: contain;
    background-repeat: no-repeat;
    margin-bottom: 50px;
  }
}
