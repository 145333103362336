@import "src/sass/utils";

.HeaderUserInfo {
  position: relative;
  display: flex;
  align-items: center;

  &__Content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    &:hover {
      cursor: pointer;
      color: var(--primary-color);
    }
  }

  &__AvatarContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    overflow: hidden;
    border-radius: 50%;

    @include phone {
      margin-right: 1rem;
      width: 4rem;
      height: 4rem;
    }
  }

  &__Avatar {
    width: 3rem;
    height: 3rem;
    max-width: 100%;
    max-height: 100%;

    @include phone {
      width: 4rem;
      height: 4rem;
    }
  }

  &__Name {
    font-weight: bold;
  }

  &__Icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
    transition: transform 0.2s ease-in;
  }

  &__List {
    position: absolute;
    right: -32px;
    top: 58px;
    opacity: 0.9;
    background-color: var(--bg-color);
    z-index: 10;
    padding: 0 1rem 1rem;
  }

  &__ListItem {
    transition: all 0.2s;
    width: 100%;
    text-align: center;
    padding: 0 2rem;
    background-color: var(--bg-color);
    opacity: 0.9;

    &:hover {
      color: var(--primary-color);
      transform: scale(1.05);
    }
  }

  &__SignIn {
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 400;
    padding: 0.5rem;
    min-width: 4rem;

    &:hover {
      color: var(--primary-color);
    }
  }

  &__icon-wallet {
    width: 3rem;
    height: 3rem;
    margin-left: 5rem;
    margin-right: 1rem;
  }
  &__icon-wallet-text {
    margin-right: 3rem;
  }
}

.chevron {
  color: var(--primary-color);
  height: 24px;
  transition: transform 0.2s;
  transform: scale(1.2);

  &-up {
    transform: rotate(180deg) scale(1.2) translate(0px, 2px);
  }
}

.Username {
  padding-left: 1em;
  padding-right: 0.75em;
}

.Signin {
  text-transform: uppercase;
  margin-left: 1em;
  margin-right: 1em;
  white-space: nowrap;
}

.dd-wrapper {
  background-color: transparent;
  z-index: 10;
  opacity: 1;
  user-select: none;

  .dd-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dd-list {
    position: absolute;
    opacity: 0.9;
    background-color: var(--bg-color);
    z-index: 10;
    right: 0;

    [dir="rtl"] & {
      text-align: right;
    }

    .dd-list-item {
      width: 100%;
      opacity: 0.87;
      font-size: 1rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.8px;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
      border-radius: 0;
      text-align: left;
      padding-left: 15px;
      box-shadow: none;
      background-color: var(--bg-color);
      [dir="rtl"] & {
        text-align: right;
      }

      &:hover {
        color: var(--primary-color);
      }

      @include tabletDown {
        white-space: pre-line;
        line-height: 1.5rem;
      }
    }
  }
}
