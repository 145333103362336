@import "src/sass/utils";

.ContinueListeningDialog {
  position: fixed;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &__popup {
    width: 45%;
    display: flex;
    flex-direction: column;

    @include tabletDown {
      width: 65%;
    }
  }

  &__image {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 14px;

    * {
      border-radius: 14px;
    }
  }

  &__action-buttons {
    display: flex;
    justify-content: space-between;
  }
}
