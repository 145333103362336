.Dialog {
  position: relative;
  top: 100px;
  width: auto;
  margin: 0 auto;
  padding-bottom: 24px;
  pointer-events: none;
  border-radius: 5px;
  border: 1px solid var(--cell-background-color);

  &-wrap {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    overflow: auto;
    outline: 0;
    -webkit-overflow-scrolling: touch;
  }

  &-title {
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    word-wrap: break-word;
  }

  &-content {
    position: relative;
    background-clip: padding-box;
    border: 0;
    border-radius: 2px;
    pointer-events: auto;
  }

  &-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    padding: 0;
    font-weight: 700;
    line-height: 1;
    height: auto;
    text-decoration: none;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: color 0.3s;
    box-shadow: none;

    &-x {
      display: block;
      width: 56px;
      height: 56px;
      font-size: 16px;
      font-style: normal;
      line-height: 56px;
      text-align: center;
      text-transform: none;
      text-rendering: auto;
      color: var(--primary-text-color);
    }

    &:focus,
    &:hover {
      text-decoration: none;
      background-color: transparent !important;
    }
  }

  &-header {
    padding: 16px 24px;
    background: #121212;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 2px 2px 0 0;
  }

  &-body {
    padding: 24px;
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word;
  }

  &-footer {
    padding: 10px 16px;
    text-align: right;
    background: transparent;
    border-top: 1px solid #f0f0f0;
    border-radius: 0 0 2px 2px;

    button + button {
      margin-bottom: 0;
      margin-left: 8px;
    }
  }

  &.zoom-enter,
  &.zoom-appear {
    transform: none; // reset scale avoid mousePosition bug
    opacity: 0;
    animation-duration: 500ms;
    user-select: none; // https://github.com/ant-design/ant-design/issues/11777
  }

  &-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);

    &-hidden {
      display: none;
    }
  }

  &-open {
    overflow: hidden;
  }
}

.Dialog-centered {
  text-align: center;

  &::before {
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle;
    content: "";
  }

  .Dialog {
    top: 0;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }
}

.zoom-appear,
.zoom-enter {
  opacity: 0;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-play-state: paused;

  &-active {
    animation-name: rcDialogZoomIn;
    animation-play-state: running;
  }
}

.zoom-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-play-state: paused;

  &-active {
    animation-name: rcDialogZoomOut;
    animation-play-state: running;
  }
}

@keyframes rcDialogZoomIn {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes rcDialogZoomOut {
  0% {
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0);
  }
}

@media (min-width: 768px) {
  .Dialog {
    width: 600px;
    margin: 30px auto;
  }
}

.fade-appear,
.fade-enter {
  opacity: 0;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;

  &-active {
    animation-name: rcDialogFadeIn;
    animation-play-state: running;
  }
}

.fade-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;

  &-active {
    animation-name: rcDialogFadeOut;
    animation-play-state: running;
  }
}

@keyframes rcDialogFadeIn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes rcDialogFadeOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
