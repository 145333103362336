@import "src/sass/utils";

.MediaAdditionalInfo {
  @include line-clamp-ellipsis(1);

  opacity: 0.87;
  font-size: 1rem;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  color: var(--primary-text-color);
}
