.ImageWithRatio {
  background-size: cover;
  -moz-background-size: cover; /* Firefox 3.6 */
  background-position: center; /* Internet Explorer 7/8 */
  background-repeat: no-repeat;
  position: relative;

  &__Content {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
  }

  &__Spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.ImageWithRatio--ratio11 {
  padding-top: 100%;
}

.ImageWithRatio--ratio169 {
  padding-top: 56.25%;
}

.ImageWithRatio--ratio167 {
  padding-top: 43.75%;
}

.ImageWithRatio--ratio166 {
  padding-top: 37.5%;
}

.ImageWithRatio--ratio165 {
  padding-top: 31.25%;
}

.ImageWithRatio--ratio43 {
  padding-top: 75%;
}

.ImageWithRatio--ratio32 {
  padding-top: 66.66%;
}

.ImageWithRatio--ratio85 {
  padding-top: 62.5%;
}
