@import "src/sass/utils";

.DesktopHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: var(--app-padding-left);
  padding-right: var(--app-padding-left); //Because padding right is different
  background: linear-gradient(to bottom, var(--header-bg-color), transparent);
  z-index: 999;
  @include prevent-tab-highlights;

  &__BackButton {
    @include prevent-tab-highlights;
    position: absolute;
    top: 125px;
    z-index: 1002;
    left: var(--app-padding-left);
    cursor: pointer;
    background: none;
    border: none;
    width: fit-content;

    [dir="rtl"] & {
      right: var(--app-padding-right);
    }

    svg {
      width: 24px;
      height: 24px;
      transform: rotate(0.5turn);
      color: var(--primary-color);
      [dir="rtl"] & {
        transform: rotate(0turn);
      }
    }

    @include desktop {
      top: 115px;
    }

    @include tabletDown {
      top: 85px;
      left: calc(var(--app-padding-left) / 3);
    }

    @include phone {
      top: 64px;
      left: var(--app-padding-left);
    }
  }

  &__Left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1 auto;
  }

  &__Right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 0 0 180px;
    z-index: 2;

    @include largeDown {
      flex-basis: 140px;
    }
  }

  @include tabletDown {
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: calc(var(--app-padding-left) / 2);
    padding-right: calc(var(--app-padding-right) / 2);
  }
}
