@import "src/sass/utils";

.ApplicationMenu {
  @include prevent-tab-highlights;
  display: flex;
  align-items: center;
  position: relative;
  flex: 1;

  .Menu {
    flex: 1;
    font-weight: 400;
    letter-spacing: 1.1px;
    margin-right: 35px;

    [dir="rtl"] & {
      text-align: right;
      margin-left: 35px;
    }
  }

  .Menu-item {
    opacity: 0.87;
  }

  &__MobileItems {
    text-align: center;
    width: 80%;

    padding: 3rem 0;

    &:not(:first-child) {
      border-top: 1px dotted #ffffff48;
    }
  }
}
