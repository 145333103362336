@import "src/sass/utils";
@import "src/sass/theme";

.GlobalSearch {
  position: absolute;
  top: 50%;
  right: 0;
  width: 0;
  transform: translateY(-50%);
  text-align: right;
  transition: all 1s;
  z-index: 2;

  [dir="rtl"] & {
    position: absolute;
    right: 100%;
    transform: translate3d(100%, -50%, 0);
  }

  .Input__icon {
    cursor: pointer;
  }

  .Input {
    padding: 0;
    justify-content: flex-end;
    transition: all 1s;

    input {
      width: 0;
      transition: all 1s;
    }
  }

  &--value,
  &--focused {
    width: 98%;

    .Input {
      width: 100%;
      padding: 15px 20px 15px 30px;

      [dir="rtl"] & {
        padding-left: 20px;
      }

      input {
        width: 100%;
      }

      @include tabletDown {
        font-size: 1.5rem;

        input {
          font-size: 1.5rem;
        }
      }
    }
  }

  &__Suggestions {
    width: 90%;
    max-height: 250px;
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 10px 30px;
    margin: 0 5%;
    overflow-y: auto;
    box-sizing: border-box;
    background-color: var(--cell-background-color);
    opacity: 0.9;
  }

  &__SuggestionValue {
    width: 100%;
    padding: 15px 10px;
    text-align: left;
    color: var(--primary-text-color);
    font-weight: 500;
    font-size: 14px;
    box-sizing: border-box;
    cursor: pointer;
  }
}
