@import "src/sass/variables";

.LabelField {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .Label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }

  label {
    font-size: $label-font-size;
    letter-spacing: 0.6px;
  }

  .required {
    color: var(--primary-color);
  }

  .Error {
    font-size: 12px;
    color: $error-color;
    list-style: none;
    margin: 0;
    margin-left: 32px;
    padding: 0;
    text-align: left;
    width: 100%;

    [dir="rtl"] & {
      text-align: initial;
    }

    li:only-child {
      margin: .6em 0;
    }
  }

  .FormInput {
    border-radius: $input-radius;
    border: 1px solid $input-bg;
    background-color: $input-bg !important;
    width: 100%;
    height: 54px;
    margin-top: 15px;
    margin-bottom: 35px;
    padding: 15px;
  }

  .FormDisabledInput {
    @extend .FormInput;
    border: 1px solid $input-disabled-bg;
    background-color: $input-disabled-bg !important;
    cursor: not-allowed;
  }

  .FormInputError {
    @extend .FormInput;
    border: 1px solid $error-color;
    margin-bottom: .67em;

    &.phone input {
      [dir="rtl"] & {
        direction: ltr;
        text-align: right;
      }
    }
  }

  .CodeInput {
    border: 1px solid $error-color;
    max-height: 78px;
    max-width: 63px;
    padding: 2px;
    text-align: center;
    background-color: #222;
    border-radius: 6px;
  }

  .Input__input {
    color: $primary;
    font-size: $input-font-size;
    font-family: var(--font-family);
  }
}
