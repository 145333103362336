.AudioPlayerController {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  background-color: #000000;
  border-top-width: 1px;
  border-top-style: solid;
  z-index: 10;

  &--mounted {
    position: relative;
  }

  &--standalone {
    min-height: 100px;
    position: fixed;
    bottom: 0;
    max-height: 10vh;
    padding-top: 0.5vh;
    padding-bottom: 0.5vh;
    width: 100vw;
  }

  &--loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
  }

  &--mobile {
    flex-direction: column;
    justify-content: space-around;
    height: 14vh;
    padding: var(--app-padding-right);
    gap: 1rem;
  }

  &__controllers {
    display: flex;
  }
}
