.MediaPeople {
  opacity: 0.87;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  letter-spacing: 0.0425rem;

  &__skeleton {
    background: var(--cell-background-color);
    height: 1.25rem;
    margin-bottom: 5px;
    border-radius: 5px;
  }
}
