@import "src/sass/variables";

$select-border: solid 1px rgba(255, 255, 255, 0.22);
// $select-color: #ffffff;
$select-font-size: 16px;
$select-letter-spacing: 0.9px;
$select-text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
$select-opacity: 0.87;
$select-padding-horizontal: 8px;
$select-padding-vertical: 8px;
// $select-selector-background: #000000;
// $select-dropdown-background: rgba(0, 0, 0, 0.7);

.Select {
  display: inline-block;
  position: relative;
  // color: $select-color;
  font-size: $select-font-size;
  letter-spacing: $select-letter-spacing;
  text-shadow: $select-text-shadow;
  min-width: 200px;
  height: max-content;
}

.Select-disabled {
  opacity: 0.3;

  input {
    cursor: not-allowed;
  }
}

.Select-single {
  cursor: pointer;
  height: max-content;

  .Select-selector {
    display: flex;
    position: relative;
    cursor: pointer;
    height: max-content;

    .Select-selection-search,
    .Select-selection-search-input {
      width: 0;
      cursor: pointer;
      height: max-content;
    }
  }
}

.Select-selection-item,
.Select-selection-placeholder {
  padding-right: 32px;
  text-transform: uppercase;
  pointer-events: none;
  opacity: $select-opacity;
}

.Select-selector {
  padding: 2 * $select-padding-vertical 2 * $select-padding-horizontal;
  border: $select-border;
  background-color: var(--bg-color);
  margin-top: 16px;
  margin-bottom: 24px;
  color: $primary;
  background-color: var(--primary-text-color);
  border-radius: 30px;
}

.Select-selection-search-input {
  border: none;
  outline: none;
  padding: 0px;
  width: 0;
  padding-right: 40px;
  height: max-content;
}

.Select-allow-clear .Select-clear {
  position: absolute;
  right: 2rem;
  top: 47%;
  transform: translateY(-50%);
}

.Select-show-arrow .Select-arrow {
  pointer-events: none;
  position: absolute;
  right: 1rem;
  top: 47%;
  transform: translateY(-50%);
  height: 100%;
  align-items: center;
  justify-content: center;

  svg {
    transition: transform 0.2s;
    display: block;
    height: 100%;
    width: 32px;
    color: var(--primary-color);
  }
}

.Select-open .Select-arrow {
  svg {
    transform: rotate(-0.5turn);
  }
}

.Select-dropdown {
  border: $select-border;
  position: absolute;
  background-color: var(--bg-color-o-30);
  box-sizing: border-box;
  font-size: $select-font-size;
  letter-spacing: $select-letter-spacing;
  text-shadow: $select-text-shadow;
  border-radius: 10px;
  z-index: 1000;
  padding: 0.25rem;

  &-hidden {
    display: none;
  }
}

.Select-item {
  padding: $select-padding-vertical $select-padding-horizontal;
  cursor: pointer;
}

.Select-item-option {
  position: relative;
}

.Select-item-option-active {
  text-decoration: underline;
}

.Select-item-option-content {
  opacity: $select-opacity;
}

.Select-item-option-disabled {
  color: #999;
}

.Select-item-empty {
  text-align: center;
}
