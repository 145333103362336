@import "src/sass/styles";
@import "src/sass/utils";

.Splash {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  @include display-algin(center);
  z-index: 1005;
  background-color: #0c101b;
  background-image: linear-gradient(to bottom, rgba(14, 17, 26, 0), #000929);

  &_errorInfo {
    display: flex;
    height: 100%;
  }

  &__container {
    display: flex;
    flex-direction: column;
    @include display-algin(center);
    height: 100%;
  }
  &__spinner {
    min-height: 20vh;
    padding: 5px;
  }
  &__logo {
    margin: 90px 0 40px;
    max-width: 615px;
    max-height: 20vh;
  }
  &__banner {
    margin: 90px 0 0;
    max-width: 615px;
    max-height: 107px;
  }
  .SplashBranding {
    min-height: 75vh;
    display: flex;
    flex-direction: column;
    @include display-algin(center);
  }
}
