@import "src/sass/utils";

.MobileHeader {
  @include prevent-tab-highlights;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: var(--app-padding-right);
  padding-bottom: var(--app-padding-right);

  background: linear-gradient(to bottom, var(--header-bg-color), transparent);

  [dir="ltr"] & {
    padding-left: 0px;
    padding-right: var(--app-padding-right);
  }

  [dir="rtl"] & {
    padding-right: 0px;
    padding-left: var(--app-padding-left);
  }

  &__BackButtonContainer {
    display: block;
    min-width: var(--app-padding-right);
  }

  &__Left {
    display: flex;
  }

  &__Right {
    gap: 1rem;
    display: flex;
    flex: 1 1;
    align-items: center;
    justify-content: flex-end;
  }

  &__BackButton {
    @include prevent-tab-highlights;
    z-index: 1002;
    cursor: pointer;
    background: none;
    border: none;

    svg {
      width: 24px;
      height: 24px;
      transform: rotate(0.5turn);
      color: var(--primary-color);
      [dir="rtl"] & {
        transform: rotate(0turn);
      }
    }
  }
}
