@import "src/sass/variables";
@import "src/sass/utils";

.Button {
  --button-size: 48px;
  --icon-with-text-size: 24px;
  --icon-without-text-size: 26px;

  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;

  min-width: var(--button-size);
  min-height: var(--button-size);
  border-radius: var(--button-size);

  border: 2px solid transparent;
  outline: none;
  cursor: pointer;
  overflow: hidden;

  padding-inline: 20px;

  font-size: 16px;
  font-weight: 700;

  background: none;
  color: var(--primary-text-color);

  transition:
    color 150ms linear,
    background 150ms linear;

  &__Wrapper {
    display: flex;
    position: relative;
  }

  &__Content {
    --progress: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--button-size);
    gap: 8px;
    z-index: 1;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: calc(100% - calc(var(--progress)) * 100%);
      background: var(--primary-text-color);
      z-index: -1;
      transition: right 1s linear;
    }
  }

  &:disabled &__Content {
    &:before {
      background: var(--icon-dark-color);
      opacity: 0.2;
    }
  }

  svg {
    width: var(--icon-with-text-size);
    height: var(--icon-with-text-size);
    transition: all 150ms linear;
  }

  &__LoaderSpinner {
    display: flex;
  }

  &.variant-primary {
    background: var(--primary-color);
    border: 2px solid transparent;

    &:hover,
    &.hover {
      background: var(--focus-color);
    }

    &.elevated svg {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }

  &.variant-secondary {
    background: none;
    border: 2px solid var(--primary-text-color);

    &.elevated svg {
      padding: 10px;
      border: 1px solid var(--primary-text-color);
    }

    &.elevated:disabled svg {
      border: 1px solid var(--icon-light-color);
    }

    &:hover,
    &:focus,
    &.hover {
      background: var(--primary-text-color);
      color: var(--bg-color);

      svg {
        border-color: var(--bg-color);
      }
    }
  }

  &.variant-tertiary {
    background: none;
    border: 2px solid transparent;

    &:hover,
    &.hover {
      border: 2px solid var(--primary-text-color);
    }
  }

  &.variant-quaternary {
    background: var(--primary-text-color);
    color: var(--bg-color);

    &.progress {
      background: var(--icon-dark-color);
    }

    &.progress:disabled {
      background: none;
    }
  }

  &.variant-labeled-icon {
    background: none;
    color: var(--primary-text-color);

    svg {
      padding: 9px;
      border-radius: 50%;
      border: 2px solid var(--primary-text-color);
    }

    &:hover,
    &:focus,
    &.hover {
      svg {
        background: var(--primary-text-color);
        color: var(--bg-color);
      }
    }

    &:disabled {
      border: none !important;

      svg {
        background: none;
        color: var(--icon-dark-color);
        border: 2px solid var(--icon-light-color);
      }
    }

    &:focus {
      border: none !important;

      svg {
        border: 2px solid var(--focus-color);
      }
    }
  }

  &.variant-link {
    background: none;
    text-decoration: none;
    color: var(--primary-text-color);

    min-height: calc(var(--button-size) / 2);
    border-radius: 0;
    padding-inline: 0;

    &:hover,
    &.hover {
      color: var(--primary-color);
    }

    &:disabled {
      border: none !important;
      color: var(--icon-dark-color);
    }
  }

  &.icon-only {
    border-radius: 50%;
    padding: 0;

    svg {
      width: var(--icon-without-text-size);
      height: var(--icon-without-text-size);
    }
  }

  &.elevated {
    padding-left: 0;

    svg {
      padding: 11px;
      margin: -4px;
      border-radius: 50%;
    }
  }

  &:disabled,
  &:disabled:hover {
    background: none;
    color: var(--icon-dark-color);
    border: 2px solid var(--icon-light-color);
    cursor: not-allowed;
  }

  &:disabled {
    opacity: 0.7;
  }

  &:focus {
    border: 2px solid var(--focus-color);
  }

  &[data-tooltip]:after {
    content: attr(data-pui-tooltip);
  }

  &__Tooltip {
    position: absolute;
    display: block;
    color: var(--primary-text-color);
    font-size: 16px;
    font-weight: 400;
    background: var(--icon-dark-color);
    padding: 7px 12px;
    border-radius: 32px;
    white-space: nowrap;
    z-index: 1;
    pointer-events: none;
    opacity: 0;

    transition-property: opacity;
    transition-duration: 150ms;
    transition-delay: 0s;

    &[data-align="left"] {
      left: 0;
    }

    &[data-align="right"] {
      right: 0;
    }

    &[data-align="center"] {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__Wrapper:hover &__Tooltip {
    @include mouse-and-touchpad {
      transition-delay: 200ms;
      opacity: 1;
    }
  }
}
