@import "src/sass/variables";

.FormButton {
  justify-content: center;
  width: 100%;
  height: 54px;
  margin-top: 30px;
  margin-bottom: 10px;
  margin-right: 0;
  border-radius: $button-radius;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0.82px;
  text-align: center;
}
