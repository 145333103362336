.LogOutModal {
    &__Actions {
      display: flex;
      flex-direction: row;
      padding: 24px 0 0;
      gap: 25px;
    }

    .Dialog-body {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .MediaButton {
      padding: 1.5rem;
      margin: unset;
    }
  }
