.Menu-item {
  color: var(--header-link-color);

  &:hover {
    color: var(--header-link-hover-color);
  }

  &__container {
    text-wrap: wrap;
    line-height: 1.4;
  }
}
